import React, { useState } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");

function Addvendor() {
    const [input, setInput] = useState({});
    const [file, setFile] = useState(null); 
    const [error, setError] = useState('');

  const handleinput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput(values => ({...values, [name]: value}))
  }


  const handleFile = (event) => {
    setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
};

const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

   
    Object.keys(input).forEach(key => {
      formData.append(key, input[key]);
    });

   
    if (file) {
      formData.append('file', file);
    }
   // openModal();
    try {
       
        const response = await axios.post('https://projects/arivomkadai/auguest/api/addvendor/', formData);
        //console.log("Response data:", response.data);
        // Redirecting to home page
 // navigate('/online');
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
   // closeModal();
};

  return (
    <>
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Product Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Aside/>
            <div className="layout-page">
                <Navbar/>
                <div className="content-wrapper">

             



                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Add Product</h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <h5 className="card-header">Add New Vendor</h5>
                                    <div className="card-body">
                                        <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="link" className="form-label">Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={input.name}
                                                        Onchange={handleinput}
                                                    />
                                                    
                                                </div>

                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="link" className="form-label">Phone Number</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="phone"
                                                        name="phone"
                                                        value={input.phone}
                                                        Onchange={handleinput}
                                                    />
                                                    
                                                </div>


                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="link" className="form-label">Email</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={input.email}
                                                        Onchange={handleinput}
                                                      
                                                    />
                                                    
                                                </div>

                                               
                                               
                                                
                                                
                                                 <div className="mb-3 col-md-12">
                                                    <label htmlFor="des" className="form-label">Address</label>
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name="des"
                                                        id="classic-editor"
                                                        value={input.address}
                                                        Onchange={handleinput}
                                                    />
                                                </div>

                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="des" className="form-label">State</label>
                                                    <input
                                                        className="form-control"
                                                        type="State"
                                                        name="State"
                                                        id="classic-editor"
                                                        value={input.state}
                                                        Onchange={handleinput}
                                                        
                                                    />
                                                </div>


                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="des" className="form-label">Pincode</label>
                                                    <input
                                                        className="form-control"
                                                        type="pincode"
                                                        name="pincode"
                                                        id="classic-editor"
                                                        value={input.pincode}
                                                        Onchange={handleinput}
                                                        
                                                    />
                                                </div>


                                                <div className="mb-3 col-md-4">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image"
                                                            id="image"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            value={file}
                                                            Onchange={handleFile}
                                                        />
                                                </div>
                                                
                                                
                                                
                                                
                                                
                                            </div>
                                            <div className="mt-2">
                                                <button type="submit" className="btn btn-primary me-2">Add</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  )
}
export default  Addvendor;
